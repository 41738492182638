<script lang="ts" setup>
import {toTypedSchema} from '@vee-validate/zod';
import * as z from 'zod';
import {useField} from 'vee-validate';
import type {HTMLAttributes} from 'vue'

const emit = defineEmits<{
    change: [password: string]
}>()

const props = defineProps({
    name: {
        type: String,
        required: true
    },
    placeholder: {
        type: String,
        default: ''
    },
    confirmPlaceholder: {
        type: String,
        default: ''
    },
    disabled: {
        type: Boolean,
        default: false
    },
    class: {
        type: String as PropType<HTMLAttributes['class']>
    }
})

const {t} = useI18n()

const validateSchema = z.string()
    .min(8, {message: t("Password must be at least 8 characters long")})
    .max(40, {message: t("Password must be no longer than 40 characters")})
    .regex(/[A-Z]/, t("Password must contain least one uppercase letter"))
    .regex(/\d/, t("Password must contain least one number"))
    .refine((val) => val === confirmPassword.value, t("Passwords must match"))

const {value: password, setValue: setPassword} = useField(props.name, toTypedSchema(validateSchema))
const confirmPassword = ref('')

const passwordVisible = ref(false)
const confirmPasswordVisible = ref(false)

const UppercaseComplete = computed(() => /[A-Z]/.test(password.value || ''))
const NumberComplete = computed(() => /\d/.test(password.value || ''))
const LengthCharacters = computed(() => (password.value || '').length >= 8)

const validatePassword = computed(() => {
    if (!/[A-Z]/.test(password.value || '') || !/\d/.test(password.value || '')) {
        return 'step_1'
    }
    if ((password.value || '').length < 8) {
        return 'step_2'
    }
})

const passwordsMatch = computed(() => {
    return password.value?.length > 7 &&
        UppercaseComplete.value &&
        NumberComplete.value &&
        confirmPassword.value && // Добавляем проверку
        confirmPassword.value === password.value
})

function handleInput(value: string, isConfirm = false) {
    const sanitized = value.replace(/\s/g, '').replace(/[^a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g, '')

    if (isConfirm) {
        confirmPassword.value = sanitized
    } else {
        setPassword(sanitized)
        if (confirmPassword.value) { // Проверяем наличие подтверждения
            emit('change', sanitized)
        }
    }
}
</script>

<template>
    <div class="">
        <UiFormField v-slot="{ componentField }" :name="name">
            <UiFormItem class="relative space-y-0 mb-2">
                <UiFormControl>
                    <UiInput :class="props.class"
                             :disabled="disabled"
                             :placeholder="placeholder"
                             :type="passwordVisible ? 'text' : 'password'"
                             :value="password"
                             class="ys-form-input ys-password pl-9 pr-9"
                             v-bind="componentField"
                             @update:modelValue="val => handleInput(val)"/>
                    <span class="absolute start-0 inset-y-0 flex items-center justify-center px-2 text-gray-500">
                    <Icon class="ml-1" name="ri:lock-2-line" size="19"/>
                </span>
                    <span class="absolute end-0 inset-y-0 flex items-center justify-center px-2 text-gray-500"
                          @click="passwordVisible = !passwordVisible">
                    <Icon class="ml-1 cursor-pointer" name="ri:eye-line" size="19"/>
               </span>
                </UiFormControl>
            </UiFormItem>
            <UiFormMessage class="mb-2"/>
        </UiFormField>

        <div class="relative">
            <UiInput :class="props.class"
                     :disabled="disabled"
                     :placeholder="confirmPlaceholder"
                     :type="confirmPasswordVisible ? 'text' : 'password'"
                     :value="confirmPassword"
                     class="ys-form-input ys-password pl-9 pr-9"
                     @update:modelValue="val => handleInput(val, true)"/>
            <span class="absolute start-0 inset-y-0 flex items-center justify-center px-2 text-gray-500">
     <Icon class="ml-1" name="ri:lock-2-line" size="19"/>
   </span>
            <span class="absolute end-0 inset-y-0 flex items-center justify-center px-2 text-gray-500"
                  @click="confirmPasswordVisible = !confirmPasswordVisible">
     <Icon class="ml-1 cursor-pointer" name="ri:eye-line" size="19"/>
   </span>
        </div>

        <div :class="{step_one: validatePassword === 'step_1', step_two: validatePassword === 'step_2'}"
             class="my-2 ys-password-progress-bar">
            <div class="single_element_one"></div>
            <div class="single_element_two"></div>
            <div class="single_element_tree"></div>
        </div>

        <p v-if="validatePassword === 'step_1'" class="ys-moderate-pass-text">{{
                $t("Weak password. Must contain at least;")
            }}</p>
        <p v-if="validatePassword === 'step_2'" class="ys-moderate-pass-text">
            {{ $t("Moderate password. Must contain at least;") }}</p>
        <p v-if="!validatePassword" class="ys-moderate-pass-text">{{
                $t("Strong password. Your password is secure.")
            }}</p>

        <div v-if='!passwordsMatch'>
            <p class="flex my-1 ys-moderate-pass-text">
                <Icon v-if="UppercaseComplete" class="mr-2" name="ri:checkbox-circle-fill" size="16"
                      style="color: var(--ys-state-success)"/>
                <Icon v-if="!UppercaseComplete" class="mr-2" name="ri:close-circle-fill" size="16"
                      style="color: var(--ys-input-text-color)"/>
                {{ $t("At least 1 uppercase") }}
            </p>
            <p class="flex my-1 ys-moderate-pass-text">
                <Icon v-if="NumberComplete" class="mr-2" name="ri:checkbox-circle-fill" size="16"
                      style="color: var(--ys-state-success)"/>
                <Icon v-if="!NumberComplete" class="mr-2" name="ri:close-circle-fill" size="16"
                      style="color: var(--ys-input-text-color)"/>
                {{ $t("At least 1 number") }}
            </p>
            <p class="flex my-1 ys-moderate-pass-text">
                <Icon v-if="LengthCharacters" class="mr-2" name="ri:checkbox-circle-fill" size="16"
                      style="color: var(--ys-state-success)"/>
                <Icon v-if="!LengthCharacters" class="mr-2" name="ri:close-circle-fill" size="16"
                      style="color: var(--ys-input-text-color)"/>
                {{ $t("At least 8 characters") }}
            </p>
        </div>
    </div>

</template>
<style lang="scss" scoped>

.ys-moderate-pass-text {
  color: rgba(82, 88, 102, 1);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
}

.ys-password-progress-bar {
  @apply flex items-center justify-between;
  width: 376px;

  .single_element_one {
    width: 120px;
    height: 4px;
    border-radius: 1.2px;
    background: rgba(56, 199, 147, 1);
    margin-right: 10px;
  }

  .single_element_two {
    width: 120px;
    height: 4px;
    border-radius: 1.2px;
    background: rgba(56, 199, 147, 1);
    margin-right: 10px;

  }

  .single_element_tree {
    width: 120px;
    height: 4px;
    border-radius: 1.2px;
    background: rgba(56, 199, 147, 1);
  }
}

.step_one {
  .single_element_one {
    background: rgba(223, 28, 65, 1);
  }

  .single_element_two {
    background: rgba(226, 228, 233, 1);
  }

  .single_element_tree {
    background: rgba(226, 228, 233, 1);
  }
}

.step_two {
  .single_element_one {
    background: rgba(241, 123, 44, 1);
  }

  .single_element_two {
    background: rgba(241, 123, 44, 1);
  }

  .single_element_tree {
    background: rgba(226, 228, 233, 1);
  }
}

</style>