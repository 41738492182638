<script lang="ts" setup>
import {ErrorMessage, useForm} from "vee-validate";
import Error from "~/components/layouts/notice/Error.vue";
import OtpSaveNewPassword from "~/components/pages/office/settings/profile/OtpSaveNewPassword.vue";
import InputSetPassword from "~/components/custom-ui/InputSetPassword.vue";
import {useToast} from "~/components/ui/toast";
import {RoleEnum} from "~/types/User";

const {t} = useI18n()
const userStore = useUserStore()
const emit = defineEmits(['back'])
const {toast} = useToast()
const props = defineProps({
    type: {
        type: String,
        default: 'email'
    },
    email: {
        type: String,
    },
    phone: {
        type: String
    },
})

const flow = ref('set_password')
const loading = ref(false)
const newPassword = ref(null)

async function onSubmit(values, context){
    newPassword.value = values.password
    flow.value = 'check_otp'
}

async function handleDone() {
    await userStore.login({
        phone: props.phone || null,
        email: props.email || null,
        password: newPassword.value,
    })
        .then((response) => {
            navigateTo({
                name: userStore.user?.user_role === RoleEnum.MASTER ? 'master_office' : 'customer_office'
            })
        })
        .catch((error) => {
            console.error(error.data)
        })
        .finally(() => {
            toast({
                title: t('Password changed successfully'),
                variant: "success"
            })
        })
}

onMounted(() => {
    newPassword.value = null
    flow.value = 'set_password'
})

</script>
<template>
    <div>
        <template v-if="flow === 'set_password'">
            <UiDialogHeader>
                <CustomUiSectionLogoIcon class="m-auto mb-2" icon_name="ri:user-fill"/>
                <UiDialogTitle class="text-center">{{ $t('Set a new password') }}</UiDialogTitle>
                <UiDialogDescription class="text-center text-gray-500 border-b-gray-300 border-b pb-5">
                    {{ $t('You will receive a notification with a confirmation code to') }} {{props.phone || props.email}}
                </UiDialogDescription>
            </UiDialogHeader>

            <UiForm @submit="onSubmit" v-slot="{meta, values, errors}" class="grid gap-4 mt-6" :key="type">
                <InputSetPassword name="password" :placeholder="$t('Password')" :confirm-placeholder="$t('Password')" />
                <ErrorMessage
                        as="p"
                        name="global"
                        v-slot="{ message }"
                        class="text-sm font-medium text-red-500 top-auto left-auto"
                >
                    <Error :message="message"/>
                </ErrorMessage>

                <div class="flex gap-4">
                    <UiButton
                            @click="emit('back')"
                            class="w-full rounded-[10px]"
                            variant="secondary"
                            type="button"
                    >
                        <span>{{ $t('Return') }}</span>
                    </UiButton>
                    <UiButton
                            :disabled="loading"
                            class="ys-button w-full"
                            type="submit"
                    >
                        <Icon v-if="loading" name="svg-spinners:ring-resize"/>
                        <span v-else>{{ $t('Send code') }}</span>
                    </UiButton>
                </div>
                
            </UiForm>
        </template>
        <template v-else-if="flow === 'check_otp'">
            <OtpSaveNewPassword
                    :email="props.email"
                    :phone="props.phone"
                    :type="props.phone ? 'phone_number' : 'email'"
                    :new-password="newPassword"
                    @done="handleDone"
            />
        </template>
    </div>
</template>